// import $t from "o365.modules.translate.ts";
//--------------------------------------------------------------------------------------------------//
//-----------------------------------       SERIES CONFIG       ----------------------------------- //
//--------------------------------------------------------------------------------------------------//
//import { chartSeriesOrderCost, chartSeriesOrderHours, chartSeries, chartHoursSeries, chartPeriodicSeries, chartPeriodicHoursSeries, chartArchiveSeries, chartArchiveHrsSeries, chartBaselineSeries} from 'cost.vue.components.CostCurves.SeriesConfig.ts'
//
//Sorting should be
// CUMULATIVE   Planned, Actual, Forecast, Earned, Booked, Commited, Paument Forecast;
// ARCHIVE      1-Value, 2-Value
// PERIODIC     same order as CUMULATIVE
// TOTALS       Forecast At Completion, Estimate At Completion
// ARCHIVE      1-Total-1, 2-Total    
export const chartSeriesOrderCost = [ // sort order
    //cumulative
    'PlannedValue',
    'ActualCost',
    'ForecastValueCurve',
    'EarnedValue',
    'Booked',
    'CommittedValue',
    'PaymentForecast',
    'OriginalBaselineValue',
    'DraftImportCurve',
    'Funding',
    //archive values
    'Archive1Value',
    'Archive2Value',
    //periodic
    'PlannedValuePeriodic',
    'ActualCostPeriodic',
    'ForecastValuePeriodicCurve',
    'EarnedValuePeriodic',
    'BookedPeriodic',
    'CommittedValuePeriodic',
    'PaymentForecastPeriodic',
    'OriginalBaselineValuePeriodic',
    'DraftImportCurvePeriodic',
    //totals
    'ForecastAtCompletion',
    'BudgetAtCompletion',
    'OriginalBaselineTotal',
    'Archive1Total',
    'Archive2Total'
] 

export const chartSeriesOrderHours = [  // sort order
    //cumulative
    'PlannedHrs',
    'ActualHrs',
    'ForecastHrsCurve',
    'EarnedHrs',
    'OriginalBaselineHrs',
    //archive
    'Archive1Hrs',
    'Archive2Hrs',
    //periodic
    'PlannedHrsPeriodic',
    'ActualHrsPeriodic',
    'ForecastHrsPeriodicCurve',
    'EarnedHrsPeriodic',
    'OriginalBaselineHrsPeriodic',
    //totals
    'ForecastHrsTotal',
    'PlannedHrsTotal',
    'OriginalBaselineHrsTotal'
    // 'Archive2Total'
]

export const chartSeries = {
    //cumulative
    PlannedValue: {
        name: $t("Planned"),
        data: [],
        color: "rgba(0, 153, 255, 1)",
        visible: true,
    },
    ActualCost: {
        name: $t("Actual"),
        data: [],
        color: "rgba(255, 0, 0, 1)",
        visible: true,
    },
    ForecastValueCurve: {
        name: $t("Forecast"),
        data: [],
        color: "rgba(0, 0, 0, 1)",
        dashStyle: "ShortDash",
        visible: true,
    },
    EarnedValue: {
        name: $t("Earned"),
        data: [],
        color: "rgba(0, 204, 0, 1)",
        visible: true,
    },
    Booked: {
        name: $t("Booked"),
        data: [],
        color: "rgba(255, 102, 51, 1)",
        visible: true,
    },
    CommittedValue: {
        name: $t("Committed"),
        data: [],
        color: "rgba(152, 107, 32, 1)",
        visible: true,
    },
    PaymentForecast: {
        name: $t("Payment Forecast"),
        data: [],
        color: "rgba(192, 192, 192, 1)",
        dashStyle: "ShortDash",
        visible: true,
    },
    Funding: {
        name: $t("Funding"),
        data: [],
        color: "rgba(245, 40, 145, 1)",
        step: "right",
        visible: true,
    },

    //totals
    ForecastAtCompletion: {
        name: $t("Forecast at Complete"),
        data: [],
        color: "rgba(0, 0, 0, 1)",
        step: "right",
        visible: true,
    },
    BudgetAtCompletion: {
        name: $t("Est at Compl (Baseline)"),
        data: [],
        color: "rgba(102, 51, 0, 1)",
        step: "right",
        visible: true,
    },
    
};

export const chartSeriesOrderContingency = [
    'Planned',
    'Actual',
    'Forecast'
];

export const chartContingencySeries = {
    //cumulative
    Planned: {
        name: $t("Planned"),
        data: [],
        color: "rgba(0, 153, 255, 1)",
        visible: true,
    },
    Actual: {
        name: $t("Actual"),
        data: [],
        color: "rgba(255, 0, 0, 1)",
        visible: true,
    },
    Forecast: {
        name: $t("Forecast"),
        data: [],
        color: "rgba(0, 0, 0, 1)",
        dashStyle: "ShortDash",
        visible: true,
    }   
};

export const chartHoursSeries = {
    PlannedHrs: {
        name: $t("Planned Hrs"),
        data: [],
        color: "rgba(0, 153, 255, 1)",
        visible: true,
    },
    ActualHrs: {
        name: $t("Actual Hrs"),
        data: [],
        color: "rgba(255, 0, 0, 1)",
        visible: true,
    },
    ForecastHrsCurve: {
        name: $t("Forecast Hrs"),
        data: [],
        color: "rgba(0, 0, 0, 1)",
        dashStyle: "ShortDash",
        visible: true,
    },
    EarnedHrs: {
        name: $t("Earned Hrs"),
        data: [],
        color: "rgba(0, 204, 0, 1)",
        dashStyle: "ShortDash",
        visible: true,
    },
    OriginalBaselineHrs: {
        name: $t("Original Hrs"),
        data: [],
        color: "rgba(27, 163, 156, 1)",
        dashStyle: "ShortDash",
        visible: true,
    },
    //totals
    ForecastHrsTotal: {
        name: $t("Forecast at Complete"),
        data: [],
        color: "rgba(0, 0, 0, 1)",
        step: "right",
        visible: true,
    },
    PlannedHrsTotal: {
        name: $t("Budget at Complete"),
        data: [],
        color: "rgba(102, 51, 0, 1)",
        step: "right",
        visible: true,
    },
    OriginalBaselineHrsTotal: {
        name: $t("Original Budget"),
        data: [],
        color: "rgba(27, 163, 156, 1)",
        step: "right",
        visible: true,
    },

};

export const chartPeriodicSeries = {
    PlannedValuePeriodic: {
        name: $t("Per. Planned"),
        data: [],
        color: "rgba(0, 153, 255, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    ActualCostPeriodic: {
        name: $t("Per. Actual"),
        data: [],
        color: "rgba(255, 0, 0, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    ForecastValuePeriodicCurve: {
        name: $t("Per. Forecast"),
        data: [],
        color: "rgba(0, 0, 0, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    EarnedValuePeriodic: {
        name: $t("Per. Earned"),
        data: [],
        color: "rgba(0, 204, 0, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    BookedPeriodic: {
        name: $t("Per. Booked"),
        data: [],
        color: "rgba(255, 102, 51, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    CommittedValuePeriodic: {
        name: $t("Per. Committed"),
        data: [],
        color: "rgba(152, 107, 32, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    PaymentForecastPeriodic: {
        name: $t("Per. Payment Forecast"),
        data: [],
        color: "rgba(192, 192, 192, 0.5)",
        dashStyle: "ShortDash",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    
};

export const chartPeriodicHoursSeries = {
    PlannedHrsPeriodic: {
        name: $t("Per. Planned Hrs"),
        data: [],
        color: "rgba(0, 153, 255, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    ActualHrsPeriodic: {
        name: $t("Per. Actual Hrs"),
        data: [],
        color: "rgba(255, 0, 0, 0.5)",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
    ForecastHrsPeriodicCurve: {
        name: $t("Per. Forecast Hrs"),
        data: [],
        color: "rgba(0, 0, 0, 0.5)",
        dashStyle: "ShortDash",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    }    
};

export const chartArchiveSeries = {
    Archive1Value: {
        name: $t("Archive1Value"),
        data: [],
        color: "rgba(56, 56, 56, 1)",
        dashStyle: "Dot",
        visible: false,
    },
    Archive2Value: {
        name: $t("Archive2Value"),
        data: [],
        color: "rgba(56, 56, 56, 1)",
        dashStyle: "Dot",
        visible: false,
    },
    Archive1Total: {
        name: $t("Archive1Total"),
        data: [],
        color: "rgba(56, 56, 56, 0.5)",
        step: "right",
        dashStyle: "Dot",
        visible: false,
    },
    Archive2Total: {
        name: $t("Archive2Total"),
        data: [],
        color: "rgba(56, 56, 56, 0.5)",
        step: "right",
        dashStyle: "Dot",
        visible: false,
    }
};

export const chartArchiveHrsSeries = {
    Archive1Hrs: {
        name: $t("Archive1Value"),
        data: [],
        color: "rgba(56, 56, 56, 1)",
        dashStyle: "Dot",
        visible: false,
    },
    Archive2Hrs: {
        name: $t("Archive2Value"),
        data: [],
        color: "rgba(56, 56, 56, 1)",
        dashStyle: "Dot",
        visible: false,
    },
    // Archive1HrsTotal: { // not exists in view for hrs
        // name: $t("Archive1Total"),
        // data: [],
        // color: "rgba(56, 56, 56, 0.5)",
        // step: "right",
        // dashStyle: "Dot",
        // visible: false,
    // },
    // Archive2HrsTotal: {
        // name: $t("Archive2Total"),
        // data: [],
        // color: "rgba(56, 56, 56, 0.5)",
        // step: "right",
        // dashStyle: "Dot",
        // visible: false,
    // }
};

export const chartBaselineSeries = {
    OriginalBaselineTotal: {
        name: $t("Original Baseline Total"),
        data: [],
        color: "rgba(27, 163, 156, 1)",
        step: "right",
        visible: false,
    },
    OriginalBaselineValue: {
        name: $t("Original Baseline"),
        data: [],
        color: "rgba(27, 163, 156, 1)",
        dashStyle: "ShortDash",
        visible: false,
    },
    OriginalBaselineValuePeriodic: {
        name: $t("Per. Original Baseline"),
        data: [],
        color: "rgba(27, 163, 156, 0.5)",
        dashStyle: "ShortDash",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
};

export const chartDraftSeries = {
        DraftImportCurve: {
        name: $t("Draft"),
        data: [],
        color: "rgba(172, 0, 240, 1)",
        dashStyle: "ShortDash",
        visible: true,
    },
}

export const chartDraftPeriodicSeries = {
        DraftImportCurvePeriodic: {
        name: $t("Draft Periodic"),
        data: [],
        color: "rgba(172, 0, 240, 0.5)",
        dashStyle: "ShortDash",
        visible: false,
        yAxis: 1,
        type: "column",
        zIndex: -1,
        maxPointWidth: 10,
    },
}